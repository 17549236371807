var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("gov-back-link", { attrs: { to: { name: "pages-index" } } }, [
        _vm._v("Back to pages")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _vm.loading
            ? _c("ck-loader")
            : _c(
                "gov-grid-row",
                [
                  _c("vue-headful", {
                    attrs: {
                      title: _vm.appName + " - Show Page " + _vm.page.title
                    }
                  }),
                  _c(
                    "gov-grid-column",
                    { attrs: { width: "full" } },
                    [
                      _c(
                        "gov-grid-row",
                        [
                          _c(
                            "gov-grid-column",
                            { attrs: { width: "two-thirds" } },
                            [
                              _c(
                                "gov-heading",
                                { attrs: { size: "m" } },
                                [
                                  _c("gov-caption", { attrs: { size: "m" } }, [
                                    _vm._v(_vm._s(_vm.page.title))
                                  ]),
                                  _vm._v(
                                    "\n              View page\n            "
                                  )
                                ],
                                1
                              ),
                              _vm.updated
                                ? _c("gov-inset-text", [
                                    _vm._v(
                                      "page " +
                                        _vm._s(_vm.page.title) +
                                        " has been updated"
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.auth.canEdit("page", _vm.page)
                            ? _c(
                                "gov-grid-column",
                                {
                                  staticClass: "text-right",
                                  attrs: { width: "one-third" }
                                },
                                [
                                  _c(
                                    "gov-button",
                                    {
                                      attrs: {
                                        to: {
                                          name: "pages-edit",
                                          params: { page: _vm.page.id }
                                        }
                                      }
                                    },
                                    [_vm._v("Edit page")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "gov-table",
                        [
                          _c(
                            "template",
                            { slot: "body" },
                            [
                              _c(
                                "gov-table-row",
                                [
                                  _c(
                                    "gov-table-header",
                                    { attrs: { top: "", scope: "row" } },
                                    [_vm._v("Page type")]
                                  ),
                                  _c("gov-table-cell", [
                                    _vm._v(
                                      _vm._s(_vm.pageTypes[_vm.page.page_type])
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "gov-table-row",
                                [
                                  _c(
                                    "gov-table-header",
                                    { attrs: { top: "", scope: "row" } },
                                    [_vm._v("Status")]
                                  ),
                                  _c("gov-table-cell", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.page.enabled
                                          ? "Enabled"
                                          : "Disabled"
                                      )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "gov-table-row",
                                [
                                  _c(
                                    "gov-table-header",
                                    { attrs: { top: "", scope: "row" } },
                                    [_vm._v("Title")]
                                  ),
                                  _c("gov-table-cell", [
                                    _vm._v(_vm._s(_vm.page.title))
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "gov-table-row",
                                [
                                  _c(
                                    "gov-table-header",
                                    { attrs: { top: "", scope: "row" } },
                                    [_vm._v("Excerpt")]
                                  ),
                                  _c("gov-table-cell", [
                                    _vm._v(_vm._s(_vm.page.excerpt || ""))
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "gov-table-row",
                                [
                                  _c(
                                    "gov-table-header",
                                    { attrs: { top: "", scope: "row" } },
                                    [_vm._v("Page content")]
                                  ),
                                  _c(
                                    "gov-table-cell",
                                    [
                                      _c("page-content", {
                                        attrs: { content: _vm.page.content }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "gov-table-row",
                                [
                                  _c(
                                    "gov-table-header",
                                    { attrs: { top: "", scope: "row" } },
                                    [_vm._v("Image")]
                                  ),
                                  _c(
                                    "gov-table-cell",
                                    [
                                      _vm.page.image
                                        ? _c("ck-image", {
                                            attrs: {
                                              "file-id": _vm.page.image.id
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        2
                      ),
                      _vm.auth.canDelete("page")
                        ? [
                            _c("gov-section-break", { attrs: { size: "l" } }),
                            _c("gov-body", [
                              _vm._v(
                                "Please be certain of the action before deleting a page"
                              )
                            ]),
                            _c("ck-delete-button", {
                              attrs: {
                                resource: "page",
                                endpoint: "/pages/" + _vm.page.id
                              },
                              on: { deleted: _vm.onDelete }
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }